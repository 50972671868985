.contactHero {
  color: #f9f9f9;
  position: relative;
}

.contactHero:before {
  content: "";
  position: absolute;
  background: url("https://images.unsplash.com/photo-1542181961-9590d0c79dab?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80")
    center;
  background-size: cover;
  filter: brightness(0.5);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.social {
  text-align: center;
  padding-bottom: 20px;
}
