@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (max-width: 600px) {
  body {
    font-size: 5vw !important;
  }
}

html {
  scroll-behavior: smooth;
}
body {
  overflow-y: hidden;
  background-color: #e7e6e3;
  font-family: ClassicGrotesqueW02, Arial, sans-serif;
  color: #2b2a29;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}
a {
  text-decoration: none;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-grow: 1;
  max-width: 1400px;
  margin: 0px auto 0px auto;
  justify-content: space-evenly;
}
