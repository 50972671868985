@media only screen and (max-width: 600px) {
  .dropdown {
    display: inline-block !important;
  }
  .navbarcontent,
  .navbarseperator {
    display: none !important;
  }
  .hmbrgr {
    display: show;
  }
  .pageheader {
    margin-top: 0px !important;
  }
}

.navroot {
  background-color: #111111;
  font-size: 12pt;
  font-weight: bold;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  z-index: 100;
}
.navlogo {
  background: url("../../old/pics/sprite.png") 0 -0px no-repeat;
  width: 60px;
  height: 60px;
  top: 10px;
  left: 25px;
  position: absolute;
  -webkit-animation: undo 0.25s steps(13, end) backwards;
  -moz-animation: undo 0.25s steps(13, end) backwards;
  -o-animation: undo 0.25s steps(13, end) backwards;
  animation: undo 0.25s steps(13, end) backwards;
}
.navlogo:hover {
  -webkit-animation: moveY 0.25s steps(13, end) forwards;
  -moz-animation: moveY 0.25s steps(13, end) forwards;
  -o-animation: moveY 0.25s steps(13, end) forwards;
  animation: moveY 0.25s steps(13, end) forwards;
  animation-fill-mode: forwards;
  position: absolute;
}
@-webkit-keyframes moveY {
  from {
    background-position-y: 0px;
  }
  to {
    background-position-y: -780px;
  }
}
@-moz-keyframes moveY {
  from {
    background-position-y: 0px;
  }
  to {
    background-position-y: -780px;
  }
}
@-o-keyframes moveY {
  from {
    background-position-y: 0px;
  }
  to {
    background-position-y: -780px;
  }
}
@keyframes moveY {
  from {
    background-position-y: 0px;
  }
  to {
    background-position-y: -780px;
  }
}
@-webkit-keyframes undo {
  from {
    background-position-y: -780px;
  }
  to {
    background-position-y: 0px;
  }
}
@-moz-keyframes undo {
  from {
    background-position-y: -780px;
  }
  to {
    background-position-y: 0px;
  }
}
@-o-keyframes undo {
  from {
    background-position-y: -780px;
  }
  to {
    background-position-y: 0px;
  }
}
@keyframes undo {
  from {
    background-position-y: -780px;
  }
  to {
    background-position-y: 0px;
  }
}
.navlogo a {
  display: block;
  height: 100%;
  width: 100%;
}
.navbarseperator {
  border-left: 2px solid #7e7e7e;
  display: inline-block;
  font-size: 14px;
  height: 30px;
  margin-left: 100px;
  margin-right: 0.25em;
  margin-top: 24px;
  vertical-align: top;
  opacity: 0.4;
}
.navbarcontent {
  height: 80px;
  width: 400px;
  left: 100px;
  text-align: center;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  top: 0;
  position: absolute;
}
.navbarlink {
  width: 100%;
  border-bottom: 2px solid transparent;
  line-height: 76px;
  margin: 0 0.85em;
  overflow: hidden;
  letter-spacing: 0.1em;
  transition: 0.1s;
}
.navbarlink a:link {
  color: #f9f9f9;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 11px;
  height: 100%;
  width: 100%;
  display: block;
}
.navbarlink a:visited {
  color: #f9f9f9;
}
.navbarlink:hover {
  border-color: #d13639;
}

/*----------Dropdown----------*/
.dropbtn {
  background-color: #111111;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: 0.2s;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  opacity: 50%;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  top: 5px;
  position: absolute;
  right: 10px;
  display: none;
}

/* Dropdown Content (Hidden by Default) */
.dropdowncontent {
  display: none;
  position: absolute;
  left: -93px;
  background-color: #d8d7d3;
  min-width: 160px;
  box-shadow: 1px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdowncontent a {
  color: #111111;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdowncontent a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdowncontent container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.hmbrgrIcon {
  width: 35px;
  height: 5px;
  background-color: #d8d7d3;
  margin: 6px 0;
}

/*----------End of Dropdown----------*/
