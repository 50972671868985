.hero {
  background: url("https://images.unsplash.com/photo-1555949963-ff9fe0c870eb?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80")
    center;
  background-size: cover;
  color: #f9f9f9;
  padding-top: 80px;
}

.pageheader {
  align-self: center;
}

.headshot {
  margin: 20px 0;
  flex-basis: 300px;
  flex-grow: 0.2;
  display: flex;
  justify-content: center;
}

.headshot img {
  border-radius: 500px;
  box-shadow: -3px 3px 8px #111111;
  width: 70%;
}
