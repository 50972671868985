.loaderwrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #111111;
  text-align: center;
  -webkit-animation: slideOut ease-in 3s;
  -moz-animation: slideOut ease-in 3s;
  -o-animation: slideOut ease-in 3s;
  animation: slideOut ease-in 3s;
  animation-fill-mode: forwards;
  z-index: 1000;
}

.loader {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* padding-top: 200px; */
  -webkit-animation: fadeIn ease-in 3s;
  -moz-animation: fadeIn ease-in 3s;
  -o-animation: fadeIn ease-in 3s;
  animation: fadeIn ease-in 3s;
  animation-fill-mode: forwards;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 300px);
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 300px);
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 300px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 300px);
  }
}

@-webkit-keyframes slideOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, -1500px);
  }
}
@-moz-keyframes slideOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, -1500px);
  }
}
@-o-keyframes slideOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, -1500px);
  }
}
@keyframes slideOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, -1500px);
  }
}

.loader img {
  width: 30%;
}
